import styled from 'styled-components';
import { ContentBlock } from '@bitcoin-portal/bitcoincom-pkg-components';
import { media } from '@bitcoin-portal/bitcoincom-pkg-theme';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  background: ${({ theme }) => theme.palette.background.contrast};
`;

export const HeadSection = styled.div`
  background-color: ${({ theme }) => theme.palette.background.dark};
`;

export const CustomContentBlock = styled(ContentBlock)`
  background: ${({ theme }) => theme.palette.background.contrast};
  & > div > div:first-child > img {
    max-height: 300px;
  }

  & h1 {
    ${({ theme }) => theme.palette.primary.text};
  }

  ${media.md`
    & > div {
      justify-content: space-between;
    }

    & > div > div {
      max-width: 46%;
    }

    & > div > div:first-child > img {
      max-height: 400px;
    }
  `};
`;

export const Logo = styled.img`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.unit * 2}px;
`;
