import styled from 'styled-components';
import {
  Section,
  ContentBlock,
  FlexContainer,
  Card,
  Tabs,
  H1,
  H3,
  H5,
  Table,
  Paragraph,
  ChevronUpSolid,
  Transactions,
  Edit,
  CheckSolid,
  Link,
} from '@bitcoin-portal/bitcoincom-pkg-components';
import { colors } from '@bitcoin-portal/bitcoincom-pkg-theme';
import Dropzone from 'react-dropzone';

const spacingUnit = 8;

export const ProgressHolder = styled.div`
  width: 64px;
  margin: auto;
`;
export const FaqLink = styled(Link)``;
export const NotaryFileDrop = styled(Dropzone)``;
export const NotaryDropContainer = styled.div`
  & > p {
    font-size: 18px;
  }
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${({ active = false }) =>
    active === true ? colors.solid.caribbeanGreen : colors.solid.vulcan};
  border-style: dashed;
  background-color: ${({ active = false }) =>
    active === true ? 'rgb(10,193,42,0.2)' : '#fafafa'};
  color: ${colors.solid.vulcan};
  outline: none;
  transition: border 0.24s ease-in-out;
  & :hover {
  }
`;
export const NotaryDropSection = styled.section``;
export const NotaryDropParagraph = styled.p``;

export const HeaderSection = styled(Section)`
  background-color: ${colors.solid.zircon};
  padding-bottom: 0px;
`;
export const NotarySection = styled(Section)`
  background-color: ${colors.solid.zircon};
`;
export const NotaryContentBlock = styled(ContentBlock)``;
export const NotaryTitle = styled(H1)``;
export const NotarySubtitle = styled(H5)``;
export const TableSection = styled(Section)``;
export const TableContentBlock = styled(ContentBlock)``;
export const TableTitle = styled(H3)`
  color: ${colors.solid.caribbeanGreen};
  text-align: center;
`;
export const TableLink = styled(Link)``;
export const ProofsTable = styled(Table)`
  text-align: center;
  min-width: 320px;
  max-width: 465px;
  margin: auto;
`;

export const ProofsTr = styled.div``;
export const ProofsTd = styled.div``;
export const HashLabelTd = styled.div`
  white-space: nowrap;
  overflow: hidden;
  max-width: 600px;
  @media screen and (max-width: 600px) {
  }
`;
export const HashTd = styled.div`
  padding-left: 24px;
  max-width: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: 600px) {
    width: 90%;
  }
`;
export const AnchorTd = styled.div`
  width: 10%;
`;
export const HashSpaceHolder = styled.span`
  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const DashboardSection = styled(Section)`
  background-color: ${colors.solid.zircon};
`;
export const TableContainer = styled(FlexContainer)`
  justify-content: center;
`;
export const DashboardFlexContainer = styled(FlexContainer)`
  justify-content: center;
`;
export const HashedFileSummary = styled(Paragraph)``;
export const DashboardCard = styled(Card)``;
export const TableCard = styled(Card)`
  max-width: 465px;
  min-width: 375px;
  @media screen and (max-width: 400px) {
    width: 100%;
  }
`;
export const DashboardCardTitle = styled(H3)``;
export const DashboardCardStep = styled(Paragraph)``;
export const DashboardTabs = styled(Tabs)``;
export const SelectIcon = styled(ChevronUpSolid)`
  color: ${colors.solid.caribbeanGreen};
`;
export const FundIcon = styled(Transactions)`
  color: ${colors.solid.caribbeanGreen};
`;
export const SignIcon = styled(Edit)`
  color: ${colors.solid.caribbeanGreen};
`;
export const ViewIcon = styled(CheckSolid)`
  color: ${colors.solid.caribbeanGreen};
`;
export const ConfirmedIconPlaceholder = styled(CheckSolid)`
  color: #fbfcff;
`;

export const NotarizeContentDiv = styled.div`
  padding-top: ${3 * spacingUnit}px;
`;
export const NotarizeTitle = styled(H3)``;
export const NotarizeInfo = styled(Paragraph)``;

// export const NotarizeFile = styled(Input)``;

// Custom file control
export const CustomFileControlContainer = styled.label`
  position: relative;
  display: inline-block;
  max-width: 100%;
  height: 2.5rem;
  margin-bottom: 0;
  cursor: pointer;
`;
export const CustomFileControlInput = styled.input`
  min-width: 14rem;
  max-width: 100%;
  height: 2.5rem;
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
`;
export const CustomFileControl = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 2.5;
  color: ${colors.solid.vulcanLight};
  pointer-events: none;
  user-select: none;
  background-color: ${colors.solid.zircon};
  border: 1px solid ${colors.solid.caribbeanGreenDark};
  border-radius: 0.25rem;
  &:before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    z-index: 6;
    display: block;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    line-height: 2.5;
    color: ${colors.solid.vulcanLight};
    background-color: ${colors.solid.caribbeanGreen};
    border: 1px solid ${colors.solid.caribbeanGreenDark};
    border-radius: 0 0.25rem 0.25rem 0;
    content: 'Browse';
  }
  &:after {
    content: 'Choose file...';
  }
`;

export const VerifyContentDiv = styled.div`
  padding-top: ${3 * spacingUnit}px;
`;
export const VerifyTitle = styled(H3)``;
export const VerifyInfo = styled(Paragraph)``;

/*
const Solid = {
  white: "#FFFFFF",
  caribbeanGreen: "#0AC18E",
  caribbeanGreenDarkest: "",
  caribbeanGreenDark: "",
  caribbeanGreenLight: "",
  caribbeanGreenLightest: "",

  vulcan: "#131720",
  vulcanLight: "#111724",
  vulcanLightest: "#6B717E",

  java: "#18E2C1",

  alto: "#D8D8D8",
  ghost: "#C7CDD9",
  ghostLight: "#C8CDD8",

  bunker: "#0D1017",
  mystic: "#E5E8F0",

  comet: "#49505F",
  cometDark: "#2D323B",
  cometDarkest: "#272B34",

  zircon: "#FBFCFF",
  zirconDark: "#FDFDFD",
  zirconDarkest: "#CDCDCD",

  pictonBlue: "#2FA9EE",
  royalBlue: "#6C43EE",
  governorBay: "#2933BC",
  gamboge: "#F09E0E",
  fuelYellow: "#EDB72E",
  cinnabar: "#E23F2E",
  violetRed: "#EE3772"
};

const Fill = {
  solid:
    "url(https://menu.cdn.bitcoindotcom.net/uni/dist/assets/images/fill.png)",
  gradient:
    "url(https://menu.cdn.bitcoindotcom.net/uni/dist/assets/images/btn.png)",
  gradientLight:
    "url(https://menu.cdn.bitcoindotcom.net/uni/dist/assets/images/btn_h.png)",
  gradientDark:
    "url(https://menu.cdn.bitcoindotcom.net/uni/dist/assets/images/btn_a.png)"
};

const Gradient = {
  caribbeanGreen: "linear-gradient(#2FA9EE, #31C275)",
  pictonBlue: "linear-gradient(#4AB8FA, #1499E2)",
  royalBlue: "linear-gradient(#8256FF, #562FDE)",
  governorBay: "linear-gradient(#2C40C9, #2625B0)",
  gamboge: "linear-gradient(#F6A414, #EA9808)",
  fuelYellow: "linear-gradient(#FFCB57, #DBA204)",
  cinnabar: "linear-gradient(#EB4B37, #D93324)",
  violetRed: "linear-gradient(#FF3F92, #DE2F51)"
};

interface Colors {
  solid: typeof Solid;
  fill: typeof Fill;
  gradient: typeof Gradient;
}


example from template
      <FormattedHTMLMessage
            id="home.notifications.giftsSwept"
            values={{
              tipsSweptCount,
              tipWalletsCount: tipWallets.length,
              sweptTxid,
              userRefundAddress: formData.userRefundAddress.value,
            }}

          />

          <FormattedMessage
                      id="home.strings.claimedCount"
                      values={{
                        tipsClaimedCount,
                        tipsTotalCount: tipWallets.length,
                      }}
                    />

          <FormattedMessage id="home.buttons.newTips" />

*/
