import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import * as CryptoJS from 'crypto-js';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './customProgress.css';
import moment from 'moment';

// Tabs does not work as a styled component
import { Tabs } from '@bitcoin-portal/bitcoincom-pkg-components';
import {
  HeaderSection,
  NotarySection,
  NotaryContentBlock,
  NotaryTitle,
  NotarySubtitle,
  DashboardSection,
  DashboardFlexContainer,
  DashboardCard,
  DashboardCardTitle,
  DashboardCardStep,
  SelectIcon,
  FundIcon,
  SignIcon,
  ViewIcon,
  NotarizeContentDiv,
  NotarizeTitle,
  VerifyContentDiv,
  VerifyTitle,
  TableSection,
  TableTitle,
  ProofsTable,
  TableLink,
  ProofsTr,
  ProofsTd,
  AnchorTd,
  HashLabelTd,
  HashTd,
  ConfirmedIconPlaceholder,
  NotaryFileDrop,
  NotaryDropContainer,
  NotaryDropSection,
  NotaryDropParagraph,
  ProgressHolder,
  HashedFileSummary,
  TableContainer,
  TableCard,
  FaqLink,
} from './styled';

// set api here
// Prod
const notaryBackendBase = 'https://notary-api.bitcoin.com/api/v1';
// Dev
// const notaryBackendBase = 'http://localhost:3003/api/v1';
// Staging
// const notaryBackendBase = 'https://notary-api.btctest.net';

// const notaryBackend = `${giftsBackendBase}/new`;
// const notaryQuery = `${giftsBackendBase}/gifts`; // :creationTxid

class Notary extends React.Component {
  constructor(props) {
    super(props);
    this.notaryPriceBch = 0.002;
    this.lastOffset = 0;
    // this.checkNotaryApi = this.checkNotaryApi.bind(this);
    this.getPriceFromLocal = this.getPriceFromLocal.bind(this);
    this.hashFile = this.hashFile.bind(this);
    this.clear = this.clear.bind(this);
    this.loadingHash = this.loadingHash.bind(this);
    this.callbackRead = this.callbackRead.bind(this);
    this.postNewProof = this.postNewProof.bind(this);

    this.state = {
      notaryPriceUsd: null,
      selectedTab: 0,
      hash: '',
      recentProofs: [],
      recentAnchors: [],
      hashProgress: 0,
      hashing: false,
      hashed: false,
    };
  }

  componentDidMount() {
    this.getPriceFromLocal();
    this.getProofsList();
    // this.checkNotaryApi('status');
  }

  componentWillUnmount() {}

  getPriceFromLocal() {
    try {
      const priceObj = JSON.parse(window.localStorage['fiat-prices']);
      const bchPrice = parseFloat(priceObj.bch.USD);
      const notaryPriceUsd = parseFloat(this.notaryPriceBch * bchPrice).toFixed(
        2,
      );
      this.setState({ notaryPriceUsd });
    } catch (err) {
      console.log(`Error obtaining price from local storage`);
      // get price elsewhere
    }
  }

  // eslint-disable-next-line react/sort-comp
  callbackRead = (reader, file, evt, callbackProgress, callbackFinal) => {
    if (this.lastOffset === reader.offset) {
      this.lastOffset = reader.offset + reader.size;
      const nextHashProgress = Math.round(100 * (reader.offset / file.size));
      // console.log(nextHashProgress);
      this.setState({ hashProgress: nextHashProgress });
      callbackProgress(evt.target.result);
      if (reader.offset + reader.size >= file.size) {
        this.lastOffset = 0;
        this.setState({ hashProgress: 100, hashing: false, hashed: true });
        callbackFinal();
      }
    } else {
      setTimeout(
        () =>
          this.callbackRead(reader, file, evt, callbackProgress, callbackFinal),
        10,
      );
    }
  };

  // eslint-disable-next-line no-return-assign
  clear = () => (this.lastOffset = 0);

  loadingHash = (file, callbackProgress, callbackFinal) => {
    const chunkSize = 1024 * 1024;
    let offset = 0;
    const size = 1024 * 1024;
    let partial;
    let index = 0;

    if (file.size === 0) {
      callbackFinal();
    }

    while (offset < file.size) {
      // console.log(`offset: ${offset}`);
      // console.log(`filesize: ${file.size}`);
      partial = file.slice(offset, offset + size);
      const reader = new FileReader();
      reader.size = chunkSize;
      reader.offset = offset;
      reader.index = index;
      reader.onload = evt =>
        this.callbackRead(reader, file, evt, callbackProgress, callbackFinal);

      reader.readAsArrayBuffer(partial);
      offset += chunkSize;
      index += 1;
    }
  };

  getProofsList() {
    const listProofsEndpoint = `${notaryBackendBase}/list/`;
    fetch(listProofsEndpoint)
      .then(response => response.json())
      .then(
        response => {
          // console.log(response);
          const recentProofs = response.proofs;
          const recentAnchors = response.anchors;
          this.setState({ recentProofs, recentAnchors });
        },
        err => {
          console.log(`Error in GET from ${listProofsEndpoint}`);
          console.log(err);
        },
      );
  }

  // eslint-disable-next-line class-methods-use-this
  postNewProof(hash) {
    // console.log(`postNewProof(${hash})`);
    // debugging
    // return console.log(`Hash: ${hash}`);
    if (hash.length !== 64) {
      return;
    }

    const newProofEndpoint = `${notaryBackendBase}/proofs/`;
    fetch(newProofEndpoint, {
      method: 'POST',
      body: hash,
    })
      .then(response => response.json())
      .then(
        response => {
          // console.log(response);
          if (response.status === 'ok') {
            // console.log(`Redirecting to /proof/?hash=${hash}`);
            document.location.href = `/proof/?hash=${hash}`;
          }
          /* Sample successful response from notary.bitcoin.com
          {
            "status": "ok",
            "url": "/proof/ce4783b04b27b4d47cc06ca36a8c896fa783cf3326f8800b4250422a0107be3a"
          }

          Upgraded response:
          {
            "status": "ok",
            "url": "/proof/3f772cc2096a317100504d7958ab4c5467729f49205f7ca8bcac703807f71e27",
            "address": "127NN3Q4QHLqVMeTMeefCVVNdie3whcbpq",
            "price": 5000
          }
          */
          // parse the URL, don't got to a new URL but instead show a component to parse
          // get the QR code and payment info
          // you'll need to add a route handler to set state based on route later
        },
        err => {
          console.log(`Error in posting ${hash} to ${newProofEndpoint}:`);
          console.log(err);
        },
      );
  }

  hashFile(fileArr) {
    this.setState({ hashing: true });
    console.log(`hashFile()`);
    console.log(fileArr);
    let file;
    try {
      // eslint-disable-next-line prefer-destructuring
      file = fileArr[0];
    } catch (err) {
      console.log(err);
      return console.log('No file in drag element');
    }

    this.clear();
    return new Promise((resolve, reject) => {
      const SHA256 = CryptoJS.algo.SHA256.create();

      this.loadingHash(
        file,
        data => {
          const wordBuffer = CryptoJS.lib.WordArray.create(data);
          SHA256.update(wordBuffer);
        },
        () => {
          const encrypted = SHA256.finalize().toString();
          this.setState({ hash: encrypted }, this.postNewProof(encrypted));
          // setLoading(false);
          reject();
        },
      );
    });
  }
  /*
  async checkNotaryApi(slug) {
    let notaryStatus;
    try {
      notaryStatus = await fetch(`${notaryBackendBase}/${slug}`);
      notaryStatus = await notaryStatus.json();
    } catch (err) {
      console.log(`Error in fetch(${notaryBackendBase}/${slug}):`);
      console.log(err);
    }
    console.log(notaryStatus);
  }
  */

  render() {
    const {
      intl: { formatMessage },
    } = this.props;
    const {
      notaryPriceUsd,
      selectedTab,
      recentProofs,
      recentAnchors,
      hashProgress,
      hash,
      hashing,
      hashed,
    } = this.state;
    const recentProofRows = [];
    const recentAnchorRows = [];
    if (recentProofs.length > 0) {
      recentProofs.forEach(proof => {
        recentProofRows.push(
          <ProofsTr key={proof.hash}>
            <AnchorTd>
              {proof.block !== null ? (
                <ViewIcon size={32} />
              ) : (
                <ConfirmedIconPlaceholder size={32} />
              )}
            </AnchorTd>
            <HashTd>
              <TableLink href={`/proof/?hash=${proof.hash}`}>
                {`${proof.hash.substr(0, 8)}...`}
              </TableLink>
            </HashTd>
            <ProofsTd>
              {moment(proof.stamp * 1000).format('YYYY-MM-DD h:mm')}
            </ProofsTd>
          </ProofsTr>,
        );
      });
    }
    if (recentAnchors.length > 0) {
      recentAnchors.forEach(anchor => {
        recentAnchorRows.push(
          <ProofsTr key={anchor.hash}>
            <AnchorTd>
              <ViewIcon size={32} />
            </AnchorTd>
            <HashTd>
              <TableLink href={`/proof/?hash=${anchor.hash}`}>
                {`${anchor.hash.substr(0, 8)}...`}
              </TableLink>
            </HashTd>
            <ProofsTd>
              {moment(anchor.stamp * 1000).format('YYYY-MM-DD h:mm')}
            </ProofsTd>
          </ProofsTr>,
        );
      });
    }
    const tabs = [
      {
        action: () => this.setState({ selectedTab: 0 }),
        label: formatMessage({
          id: 'home.tabs.notarize.tabTitle',
        }),
        className: 'selected',
      },
      {
        action: () => this.setState({ selectedTab: 1 }),
        label: formatMessage({
          id: 'home.tabs.verify.tabTitle',
        }),
      },
    ];
    return (
      <>
        <HeaderSection>
          <NotaryContentBlock>
            <NotaryTitle>
              <FormattedMessage id="home.header.title" />
            </NotaryTitle>
            <NotarySubtitle>
              {notaryPriceUsd !== null ? (
                <FormattedMessage
                  id="home.header.subtitle"
                  values={{
                    notaryPriceUsd,
                    notaryPriceBch: this.notaryPriceBch,
                  }}
                />
              ) : (
                <FormattedMessage id="home.header.subtitleNoPrice" />
              )}
            </NotarySubtitle>
            [&nbsp;
            <FaqLink href="/faq">
              <FormattedMessage id="home.header.faqLink" />
            </FaqLink>
            &nbsp;|&nbsp;
            <FaqLink href="/api">
              <FormattedMessage id="home.header.apiLink" />
            </FaqLink>
            &nbsp;]
          </NotaryContentBlock>
        </HeaderSection>

        <NotarySection>
          <NotaryContentBlock>
            <Tabs selectedTab={selectedTab} tabs={tabs} />
            {selectedTab === 0 && (
              <NotarizeContentDiv>
                <NotarizeTitle>
                  <FormattedMessage id="home.tabs.notarize.title" />
                </NotarizeTitle>
                {hashing && (
                  <ProgressHolder>
                    <CircularProgressbar
                      value={hashProgress}
                      maxValue={100}
                      text={`${hashProgress}%`}
                    />
                  </ProgressHolder>
                )}
                {!hashing && !hashed && (
                  <NotaryFileDrop
                    onDrop={fileArr => this.hashFile(fileArr)}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps, isDragActive }) => (
                      <NotaryDropSection>
                        <NotaryDropContainer
                          active={isDragActive}
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          {isDragActive ? (
                            <NotaryDropParagraph>
                              <FormattedMessage id="home.fileDrop.hover" />
                            </NotaryDropParagraph>
                          ) : (
                            <NotaryDropParagraph>
                              <FormattedMessage id="home.fileDrop.instructions" />
                            </NotaryDropParagraph>
                          )}
                        </NotaryDropContainer>
                      </NotaryDropSection>
                    )}
                  </NotaryFileDrop>
                )}
                {hashed && (
                  <>
                    <HashedFileSummary>
                      <FormattedMessage id="home.hashed.complete" />
                    </HashedFileSummary>
                    <HashedFileSummary>{hash}</HashedFileSummary>
                    <HashedFileSummary>
                      <FormattedMessage id="home.hashed.redir" />
                    </HashedFileSummary>
                  </>
                )}
              </NotarizeContentDiv>
            )}
            {selectedTab === 1 && (
              <VerifyContentDiv>
                <VerifyTitle>
                  <FormattedMessage id="home.tabs.verify.title" />
                </VerifyTitle>

                {hashing && (
                  <ProgressHolder>
                    <CircularProgressbar
                      value={hashProgress}
                      maxValue={100}
                      text={`${hashProgress}%`}
                    />
                  </ProgressHolder>
                )}
                {!hashing && !hashed && (
                  <NotaryFileDrop
                    onDrop={fileArr => this.hashFile(fileArr)}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps, isDragActive }) => (
                      <NotaryDropSection>
                        <NotaryDropContainer
                          active={isDragActive}
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />

                          {isDragActive ? (
                            <NotaryDropParagraph>
                              <FormattedMessage id="home.fileDrop.hover" />
                            </NotaryDropParagraph>
                          ) : (
                            <NotaryDropParagraph>
                              <FormattedMessage id="home.fileDrop.instructions" />
                            </NotaryDropParagraph>
                          )}
                        </NotaryDropContainer>
                      </NotaryDropSection>
                    )}
                  </NotaryFileDrop>
                )}
                {hashed && (
                  <>
                    <HashedFileSummary>
                      <FormattedMessage id="home.hashed.complete" />
                    </HashedFileSummary>
                    <HashedFileSummary>{hash}</HashedFileSummary>
                    <HashedFileSummary>
                      <FormattedMessage id="home.hashed.redir" />
                    </HashedFileSummary>
                  </>
                )}
              </VerifyContentDiv>
            )}
          </NotaryContentBlock>
        </NotarySection>
        <TableSection>
          <TableContainer columns={2}>
            <TableCard centered padded direction="column">
              <TableTitle>
                <FormattedMessage id="home.table.proofsTitle" />
              </TableTitle>
              <ProofsTable columns={3}>
                <ProofsTr>
                  <AnchorTd />
                  <HashLabelTd style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="home.table.hashLabel" />
                  </HashLabelTd>
                  <ProofsTd style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="home.table.stampLabel" />
                  </ProofsTd>
                </ProofsTr>
                {recentProofRows}
              </ProofsTable>
            </TableCard>
            <TableCard centered padded direction="column">
              <TableTitle>
                <FormattedMessage id="home.table.anchorsTitle" />
              </TableTitle>
              <ProofsTable columns={3}>
                <ProofsTr>
                  <AnchorTd />
                  <HashLabelTd style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="home.table.hashLabel" />
                  </HashLabelTd>
                  <ProofsTd style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="home.table.stampLabel" />
                  </ProofsTd>
                </ProofsTr>
                {recentAnchorRows}
              </ProofsTable>
            </TableCard>
          </TableContainer>
        </TableSection>
        <DashboardSection>
          <NotaryContentBlock>
            <DashboardFlexContainer columns={2}>
              <DashboardCard centered padded direction="column">
                <DashboardCardTitle>
                  <FormattedMessage id="home.instructionCards.titles.select" />
                </DashboardCardTitle>
                <SelectIcon size={64} />
                <DashboardCardStep>
                  <FormattedMessage id="home.instructionCards.steps.select" />
                </DashboardCardStep>
              </DashboardCard>
              <DashboardCard centered padded direction="column">
                <DashboardCardTitle>
                  <FormattedMessage id="home.instructionCards.titles.fund" />
                </DashboardCardTitle>
                <FundIcon size={64} />
                <DashboardCardStep>
                  <FormattedMessage id="home.instructionCards.steps.fund" />
                </DashboardCardStep>
              </DashboardCard>
              <DashboardCard centered padded direction="column">
                <DashboardCardTitle>
                  <FormattedMessage id="home.instructionCards.titles.sign" />
                </DashboardCardTitle>
                <SignIcon size={64} />
                <DashboardCardStep>
                  <FormattedMessage id="home.instructionCards.steps.sign" />
                </DashboardCardStep>
              </DashboardCard>
              <DashboardCard centered padded direction="column">
                <DashboardCardTitle>
                  <FormattedMessage id="home.instructionCards.titles.view" />
                </DashboardCardTitle>
                <ViewIcon size={64} />
                <DashboardCardStep>
                  <FormattedMessage id="home.instructionCards.steps.view" />
                </DashboardCardStep>
              </DashboardCard>
            </DashboardFlexContainer>
          </NotaryContentBlock>
        </DashboardSection>
      </>
    );
  }
}

Notary.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  locale: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
    messages: PropTypes.object,
  }).isRequired,
};

export default injectIntl(Notary);
